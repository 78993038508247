/**********************************************************************************
** COMPONENT
** Name : Button
***********************************************************************************/

.c-button {
  position: relative;
  display: inline-block;
  text-align: center;
  background-image: linear-gradient(to top right, $color-primary, $color-secondary);
  @include rem(border-radius, 50px);

  @include mq($until: small) {
    @include rem(padding, 10px $gutter-size/3);
  }

  @include mq($from: small, $until: medium) {
    @include rem(padding, 15px $gutter-size/2);
  }

  @include mq($from: medium, $until: large) {
    @include rem(padding, 15px $gutter-size*0.75);
  }

  @include mq($from: large) {
    @include rem(padding, 17px $gutter-size);
  }
}


  /**
  ** Label
  ****************************************/

  .c-button__label {
    font-weight: $font-bold;

    @include mq($until: small) {
      @include font-size(16px, 16px);
    }

    @include mq($from: small, $until: medium) {
      @include font-size(18px, 18px);
    }

    @include mq($from: medium, $until: large) {
      @include font-size(20px, 20px);
    }

    @include mq($from: large) {
      @include font-size(22px, 22px);
      transition: transform 0.3s ease;

      .c-button:hover & {
        transform: translate3d(-15px, 0, 0);
      }
    }
  }


  /**
  ** Suffix
  ****************************************/

  .c-button__suffix {

    @include mq($until: medium) {
      @include font-size(12px, 12px);
    }

    @include mq($from: medium) {
      @include font-size(15px, 15px);
    }

    @include mq($from: large) {
      transition: transform 0.3s ease;

      .c-button:hover & {
        transform: translate3d(-15px, 0, 0);
      }
    }
  }


  /**
  ** Icon
  ****************************************/

  .c-button__icon {
    position: absolute;
    top: 50%;
    @include rem(right, 20px);
    opacity: 0;
    transform: translateY(-50%);
    transition: opacity 0.3s ease;

    .c-button:hover & {
      opacity: 1;
    }
  }
