/**********************************************************************************
** COMPONENT
** Name : Media
***********************************************************************************/

.c-media {

  @include mq($from: xlarge) {
    @include rem(margin-right, -$gutter-size*2);
    @include rem(margin-left, -$gutter-size*2);
  }

  &:not(:first-child) {

    @include mq($until: small) {
      @include rem(margin-top, $gutter-size);
      @include rem(margin-bottom, $gutter-size);
    }

    @include mq($from: small, $until: medium) {
      @include rem(margin-top, $gutter-size*1.5);
      @include rem(margin-bottom, $gutter-size*1.5);
    }

    @include mq($from: medium, $until: large) {
      @include rem(margin-top, $gutter-size*2);
      @include rem(margin-bottom, $gutter-size*2);
    }

    @include mq($from: large, $until: xlarge) {
      @include rem(margin-top, $gutter-size*2.5);
      @include rem(margin-bottom, $gutter-size*2.5);
    }

    @include mq($from: xlarge) {
      @include rem(margin-top, $gutter-size*3);
      @include rem(margin-bottom, $gutter-size*3);
    }
  }
}


  /**
  ** Picture
  ****************************************/
  
  .c-media__picture {
    height: auto;
  }
