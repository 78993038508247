/**********************************************************************************
** SCOPE
** Name : Richtext
***********************************************************************************/

.s-richtext {

  h2 {
    @extend .c-title;
    @extend .c-title--small;

    &:not(:first-child) {

      @include mq($until: medium) {
        @include rem(margin-top, $gutter-size);
      }

      @include mq($from: medium) {
        @include rem(margin-top, $gutter-size * 1.5);
      }
    }
  }

  h3,
  p {

    &:not(:first-child) {
      @include rem(margin-top, $gutter-size * 0.5);
    }

    @include mq($until: small) {
      @include font-size(16px, 20px);
    }

    @include mq($from: small, $until: medium) {
      @include font-size(18px, 24px);
    }

    @include mq($from: medium) {
      @include font-size(20px, 26px);
    }
  }

  h3 {
    font-weight: $font-bold;
  }

  p {}

  a {
    text-decoration: none;
    color: $color-secondary;

    &:hover {
      text-decoration: underline;
    }
  }
}


/**
** Highlight
****************************************/

.s-richtext--highlight {

  p {

    &:not(:first-child) {

      @include mq($until: medium) {
        @include rem(margin-top, $gutter-size * 0.5);
      }

      @include mq($from: medium) {
        @include rem(margin-top, $gutter-size);
      }
    }

    @include mq($until: small) {
      @include font-size(18px, 24px);
    }

    @include mq($from: small, $until: medium) {
      @include font-size(21px, 28px);
    }

    @include mq($from: medium, $until: large) {
      @include font-size(24px, 32px);
    }

    @include mq($from: large) {
      @include font-size(26px, 40px);
    }

    strong {
      font-weight: $font-bold;
      background-color: $color-primary;
    }
  }
}