/**********************************************************************************
** VIEW
** Name : Events
***********************************************************************************/

.v-events {}


  /**
  ** Item
  ****************************************/
  
  .v-events__item {
    text-align: center;
    
    &:not(:first-child) {

      @include mq($until: medium) {
        margin-top: rem($gutter-size * 1.5);
      }

      @include mq($from: medium, $until: large) {
        margin-top: rem($gutter-size * 2.25);
      }

      @include mq($from: large) {
        margin-top: rem($gutter-size * 3);
      }
    }
  }


    /**
    ** Head
    ****************************************/
    
    .v-events__head {
      position: relative;
      z-index: 1;
      transition: transform 0.2s ease;

      &:before,
      &:after {
        content: '';
        position: absolute;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        mix-blend-mode: difference;
        transition: transform 0.2s ease, opacity 0.2s ease;
        pointer-events: none;
      }

      &:before {
        opacity: 0.2;
        z-index: 2;

        .v-events__item:nth-child(odd) & {
          background-color: $color-secondary;
        }

        .v-events__item:nth-child(even) & {
          background-color: $color-primary;
        }

        @include mq($until: medium) {
          transform: translate3d(#{rem(-2px)}, #{rem(-5px)}, 0);
        }

        @include mq($from: medium, $until: large) {
          transform: translate3d(#{rem(-5px)}, #{rem(-10px)}, 0);
        }

        @include mq($from: large) {
          transform: translate3d(#{rem(-10px)}, #{rem(-15px)}, 0);
        }
      }

      &:after {
        opacity: 0.3;
        z-index: 3;

        .v-events__item:nth-child(odd) & {
          background-color: $color-primary;
        }

        .v-events__item:nth-child(even) & {
          background-color: $color-secondary;
        }

        @include mq($until: medium) {
          transform: translate3d(#{rem(5px)}, #{rem(2px)}, 0);
        }

        @include mq($from: medium, $until: large) {
          transform: translate3d(#{rem(10px)}, #{rem(5px)}, 0);
        }

        @include mq($from: large) {
          transform: translate3d(#{rem(20px)}, #{rem(10px)}, 0);
        }
      }

      .v-events__item:hover & {
        transform: scale(0.95);

        &:before,
        &:after {
          transform: translate3d(0, 0, 0);
          opacity: 0.5;
        }
      }
    }


    /**
    ** Body
    ****************************************/
    
    .v-events__body {
      z-index: 2;
      
      &:not(:first-child) {

        @include mq($until: medium) {
          margin-top: rem($gutter-size * 0.5);
        }

        @include mq($from: medium, $until: large) {
          margin-top: rem(-$gutter-size * 1.5);
        }

        @include mq($from: large) {
          margin-top: rem(-$gutter-size * 2);
        }
      }
    }
