/**********************************************************************************
** COMPONENT
** Name : Gallery
***********************************************************************************/

.c-gallery {
  display: flex;
  flex-direction: row;
  flex-wrap: nowrap;
  align-items: flex-start;

  @include mq($until: medium) {
    @include rem(margin-right, -$gutter-size/4);
    @include rem(margin-left, -$gutter-size/4);
  }

  @include mq($from: medium, $until: xlarge) {
    @include rem(margin-right, -$gutter-size);
    @include rem(margin-left, -$gutter-size);
  }

  @include mq($from: xlarge) {
    @include rem(margin-right, -$gutter-size*4);
    @include rem(margin-left, -$gutter-size*4);
  }

  &:not(:first-child) {

    @include mq($until: small) {
      @include rem(margin-top, $gutter-size);
      @include rem(margin-bottom, $gutter-size);
    }

    @include mq($from: small, $until: medium) {
      @include rem(margin-top, $gutter-size*1.5);
      @include rem(margin-bottom, $gutter-size*1.5);
    }

    @include mq($from: medium, $until: large) {
      @include rem(margin-top, $gutter-size*2);
      @include rem(margin-bottom, $gutter-size*2);
    }

    @include mq($from: large, $until: xlarge) {
      @include rem(margin-top, $gutter-size*2.5);
      @include rem(margin-bottom, $gutter-size*2.5);
    }

    @include mq($from: xlarge) {
      @include rem(margin-top, $gutter-size*3);
      @include rem(margin-bottom, $gutter-size*3);
    }
  }
}


  /**
  ** Picture
  ****************************************/
  
  .c-gallery__picture {
    width: 50%;
    height: auto;

    @include mq($until: medium) {
      @include rem(padding-right, $gutter-size/4);
      @include rem(padding-left, $gutter-size/4);
    }

    @include mq($from: medium) {
      @include rem(padding-right, $gutter-size);
      @include rem(padding-left, $gutter-size);
    }
  }
