/**********************************************************************************
** LAYOUT
** Name : Header
***********************************************************************************/

.l-header {
  position: relative;

  body.page-home & {
    opacity: 0;
    transition: opacity 1s ease 0.2s;
  }

  body.is-ready & {
    opacity: 1;
  }
}


  /**
  ** Container
  ****************************************/

  .l-header__container {
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    align-items: center;

    @include mq($until: medium) {
      @include rem(padding-top, $gutter-size*0.75);
    }

    @include mq($from: medium, $until: large) {
      @include rem(padding-top, $gutter-size);
    }

    @include mq($from: large, $until: xlarge) {
      @include rem(padding-top, $gutter-size*1.5);
    }

    @include mq($from: xlarge) {
      @include rem(padding-top, $gutter-size*2);
    }
  }


    /**
    ** Home
    ****************************************/

    .l-header__home {
      
      @include mq($until: large) {
        flex: 1 1 auto;
        z-index: $z-header;
      }
      
      @include mq($from: large) {
        flex: 0 0 auto;
      }
    }


      /**
      ** Logo
      ****************************************/

      .l-header__logo {
        height: auto;
        fill: $color-foreground;

        @include mq($until: small) {
          @include rem(width, 72px);
        }

        @include mq($from: small, $until: medium) {
          @include rem(width, 96px);
        }

        @include mq($from: medium, $until: large) {
          @include rem(width, 120px);
        }

        @include mq($from: large, $until: xlarge) {
          @include rem(width, 150px);
        }
      }


    /**
    ** Navigation
    ****************************************/

    .l-header__navigation {

      @include mq($until: large) {
        position: fixed;
        display: block;
        top: 0;
        right: 0;
        bottom: 0;
        left: 0;
        @include rem(padding, $gutter-size*2.5 $gutter-size*0.75);
        background-color: rgba($color-primary, 1);
        transform: translate3d(100%, 0, 0);
        opacity: 0;
        transition: transform 0.4s ease, opacity 0.4s ease;
        z-index: $z-overlay;

        body.menu-opened & {
          transform: translate3d(0, 0, 0);
          opacity: 1;
        }
      }

      @include mq($from: large) {
        flex: 1 0 auto;
      }
    }


      /**
      ** Items
      ****************************************/

      .l-header__items {
        display: flex;
        flex-wrap: nowrap;
        align-items: center;

        @include mq($until: large) {
          flex-direction: column;
          justify-content: center;
          height: 100%;
        }

        @include mq($from: large) {
          flex-direction: row;
          justify-content: flex-end;
        }
      }


        /**
        ** Item
        ****************************************/

        .l-header__item {

          @include mq($until: small) {

            &:not(:first-child) {
              @include rem(margin-top, $gutter-size);
            }
          }

          @include mq($from: small, $until: medium) {

            &:not(:first-child) {
              @include rem(margin-top, $gutter-size * 1.25);
            }
          }

          @include mq($from: medium, $until: large) {

            &:not(:first-child) {
              @include rem(margin-top, $gutter-size * 1.5);
            }
          }

          @include mq($until: large) {
            text-align: center;
          }

          @include mq($from: large, $until: xlarge) {

            &:not(:first-child) {
              @include rem(margin-left, $gutter-size * 0.5);
            }
          }

          @include mq($from: xlarge) {

            &:not(:first-child) {
              @include rem(margin-left, $gutter-size);
            }
          }
        }


          /**
          ** Link
          ****************************************/

          .l-header__link {}


            /**
            ** Label
            ****************************************/

            .l-header__label {
              position: relative;
              display: block;

              @include mq($until: small) {
                @include font-size(26px, 26px);
              }

              @include mq($from: small, $until: medium) {
                @include font-size(32px, 32px);
              }

              @include mq($from: medium, $until: large) {
                @include font-size(36px, 36px);
              }

              @include mq($from: large) {
                @include rem(padding-right, 5px);
                @include rem(padding-left, 5px);

                &:before {
                  content: '';
                  position: absolute;
                  @include rem(height, 14px);
                  right: 0;
                  @include rem(bottom, -4px);
                  left: 0;
                  background-color: $color-primary;
                  transform: scaleX(0);
                  transform-origin: right;
                  transition: transform 0.3s ease;
                  z-index: -1;
                }

                .l-header__link:hover &,
                body.page-manifeste .l-header__link[data-page='manifeste'] &,
                body.page-informations-utiles .l-header__link[data-page='informations-utiles'] &,
                body.page-contact .l-header__link[data-page='contact'] & {

                  &:before {
                    transform: scaleX(1);
                    transform-origin: left;
                  }
                }
              }

              @include mq($from: large, $until: xlarge) {
                @include font-size(20px, 20px);
              }

              @include mq($from: xlarge) {
                @include font-size(22px, 22px);
              }
            }


    /**
    ** Buttons
    ****************************************/

    .l-header__buttons {
      position: relative;
      flex: 0 0 auto;

      @include mq($until: large) {
        z-index: $z-header;
      }

      @include mq($from: large, $until: xlarge) {

        &:not(:first-child) {
          @include rem(margin-left, $gutter-size*0.75);
        }
      }

      @include mq($from: xlarge) {

        &:not(:first-child) {
          @include rem(margin-left, $gutter-size*1.5);
        }
      }
    }


      /**
      ** Button
      ****************************************/

      .l-header__button {}


    /**
    ** Menu
    ****************************************/

    .l-header__menu {
      position: relative;

      @include mq($until: small) {

        &:not(:first-child) {
          @include rem(margin-left, $gutter-size/2);
        }
      }

      @include mq($from: small, $until: medium) {

        &:not(:first-child) {
          @include rem(margin-left, $gutter-size*0.75);
        }
      }

      @include mq($from: medium, $until: large) {

        &:not(:first-child) {
          @include rem(margin-left, $gutter-size);
        }
      }

      @include mq($until: large) {
        z-index: $z-header;
      }
      
      @include mq($from: large) {
        display: none;
      }
    }


      /**
      ** Icon
      ****************************************/

      .l-header__icon {

        @include mq($until: small) {
          @include rem(width, 20px);
          height: auto;
        }
      }
