/**********************************************************************************
** VIEW
** Name : Venues
***********************************************************************************/

.v-venues {}


  /**
  ** Head
  ****************************************/
  
  .v-venues__head {}


  /**
  ** Body
  ****************************************/
  
  .v-venues__body {

    &:not(:first-child) {

      @include mq($until: medium) {
        @include rem(margin-top, $gutter-size);
      }

      @include mq($from: medium) {
        @include rem(margin-top, $gutter-size * 1.5);
      }
    }
  }


    /**
    ** Items
    ****************************************/
    
    .v-venues__items {

      @include mq($from: medium) {
        display: flex;
        flex-direction: row;
        flex-wrap: wrap;
        @include rem(margin-right, -$gutter-size * 0.5);
        @include rem(margin-left, -$gutter-size * 0.5);
      }
    }


      /**
      ** Item
      ****************************************/
      
      .v-venues__item {

        @include mq($until: medium) {
          text-align: center;

          &:not(:first-child) {
            @include rem(margin-top, $gutter-size);
            @include rem(padding-top, $gutter-size);
            border-top: 1px solid #444;
          }
        }

        @include mq($from: medium) {
          @include rem(padding, $gutter-size * 0.5);
        }

        @include mq($from: medium, $until: large) {
          width: 50%;
        }

        @include mq($from: large) {
          width: 33.333%;
        }
      }


        /**
        ** Label
        ****************************************/
        
        .v-venues__label {
          font-weight: $font-bold;
          color: $color-primary;

          @include mq($until: small) {
            @include font-size(18px, 18px);
          }

          @include mq($from: small, $until: medium) {
            @include font-size(20px, 20px);
          }

          @include mq($from: medium, $until: large) {
            @include font-size(24px, 24px);
          }

          @include mq($from: small) {
            @include font-size(26px, 26px);
          }
        }


        /**
        ** Title
        ****************************************/
        
        .v-venues__title {
          font-weight: $font-bold;

          @include mq($until: small) {
            @include font-size(22px, 22px);
          }

          @include mq($from: small, $until: medium) {
            @include font-size(26px, 26px);
          }

          @include mq($from: medium, $until: large) {
            @include font-size(30px, 30px);
          }

          @include mq($from: small) {
            @include font-size(34px, 34px);
          }
        }


        /**
        ** Description
        ****************************************/
        
        .v-venues__description {
          font-weight: $font-bold;

          @include mq($until: medium) {
            @include font-size(16px, 20px);

            &:not(:first-child) {
              @include rem(margin-top, $gutter-size * 0.25);
            }
          }

          @include mq($from: medium) {
            @include font-size(20px, 26px);

            &:not(:first-child) {
              @include rem(margin-top, $gutter-size * 0.5);
            }
          }
        }


        /**
        ** Parking
        ****************************************/
        
        .v-venues__parking {
          @include font-size(16px, 20px);

          @include mq($until: medium) {

            &:not(:first-child) {
              @include rem(margin-top, $gutter-size * 0.25);
            }
          }

          @include mq($from: medium) {

            &:not(:first-child) {
              @include rem(margin-top, $gutter-size * 0.5);
            }
          }
        }


        /**
        ** Contact
        ****************************************/
        
        .v-venues__contact {
          @include font-size(16px, 20px);

          @include mq($until: medium) {

            &:not(:first-child) {
              @include rem(margin-top, $gutter-size * 0.25);
            }
          }

          @include mq($from: medium) {

            &:not(:first-child) {
              @include rem(margin-top, $gutter-size * 0.5);
            }
          }

          h4 {
            position: relative;
            display: inline-block;
            @include rem(margin-left, -5px);
            @include rem(margin-bottom, 5px);
            @include rem(padding-right, 5px);
            @include rem(padding-left, 5px);
            font-weight: $font-bold;

            &:before {
              content: '';
              position: absolute;
              @include rem(height, 8px);
              right: 0;
              bottom: 0;
              left: 0;
              background-color: $color-primary;
              z-index: -1;
            }
          }
        }
