/**********************************************************************************
** COMPONENT
** Name : Highlight
***********************************************************************************/

.c-highlight {}


  /**
  ** Head
  ****************************************/
  
  .c-highlight__head {}


  /**
  ** Body
  ****************************************/
  
  .c-highlight__body {

    &:not(:first-child) {

      @include mq($until: medium) {
        @include rem(margin-top, $gutter-size);
      }

      @include mq($from: medium) {
        @include rem(margin-top, $gutter-size * 2);
      }
    }
  }


    /**
    ** Introduction
    ****************************************/
    
    .c-highlight__introduction {

      @include mq($until: small) {
        @include font-size(18px, 24px);
        font-weight: $font-bold;
      }

      @include mq($from: small, $until: medium) {
        @include font-size(22px, 26px);
      }

      @include mq($from: medium, $until: large) {
        @include font-size(26px, 32px);
      }

      @include mq($from: large) {
        @include font-size(30px, 40px);
      }

      a {
        color: $color-secondary;

        &:hover {
          text-decoration: underline;
        }
      }
    }


    /**
    ** Description
    ****************************************/
    
    .c-highlight__description {

      &:not(:first-child) {
        @include rem(margin-top, $gutter-size * 0.5);
      }

      @include mq($until: medium) {
        @include font-size(18px, 24px);
      }

      @include mq($from: medium) {
        @include font-size(20px, 26px);
      }

      a {
        color: $color-secondary;

        &:hover {
          text-decoration: underline;
        }
      }
    }


    /**
    ** Logos
    ****************************************/
    
    .c-highlight__logos {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;

      &:not(:first-child) {
        @include rem(margin-top, $gutter-size * 0.5);
      }
    }


      /**
      ** Logo
      ****************************************/
      
      .c-highlight__logo {

        &:not(:first-child) {

          @include mq($until: small) {
            @include rem(margin-left, $gutter-size * 0.5);
          }

          @include mq($from: small, $until: medium) {
            @include rem(margin-left, $gutter-size);
          }

          @include mq($from: medium) {
            @include rem(margin-left, $gutter-size * 1.5);
          }
        }
      }


        /**
        ** Picture
        ****************************************/
        
        .c-highlight__picture {}
