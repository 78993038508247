/**********************************************************************************
** SETTINGS
** Name : Layout
***********************************************************************************/


/**
** Breakpoints
****************************************/

$mq-breakpoints: (
  "small": 480px,
  "medium": 720px,
  "large": 960px,
  "xlarge": 1280px
);


/**
** Container
****************************************/

$container-width: 1280px;
$container-small: 960px;
$container-large: 1440px;


/**
** Gutter
****************************************/

$gutter-size: 40px;


/**
** Z-index
****************************************/

$z-header: 99;
$z-main: 9;
$z-footer: 19;
$z-overlay: 49;


/**
** Rem
****************************************/

$rem-fallback: true;
$rem-baseline: 10px;
