.u-glitch {
  position: relative;

  .u-glitch & {
    position: inherit;
  }
}

  .u-glitch-item {
    @include rem(padding-right, 5px);

    &:first-child {
      position: relative;
      z-index: 1;
    }

    &:nth-child(2),
    &:nth-child(3) {
      position: absolute;
      top: 0;
      left: 0;
      -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
      clip-path: polygon(0 0, 0 0, 0 0, 0 0);
    }

    &:nth-child(2) {
      color: $color-secondary;
    }

    &:nth-child(3) {
      color: $color-primary;
    }

    .u-glitch:hover & {

      &:first-child {
        animation-name: glitch-anim-text;
        animation-duration: 3s;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
        animation-delay: 0s;
      }

      &:nth-child(2),
      &:nth-child(3) {
        animation-name: glitch-anim-text-back;
        animation-timing-function: linear;
        animation-iteration-count: infinite;
      }

      &:nth-child(2) {
        animation-duration: 3s;
        animation-delay: 0.025s;
      }

      &:nth-child(3) {
        animation-duration: 4s;
        animation-delay: 0.05s;
      }
    }
  }
