/**********************************************************************************
** VIEW
** Name : Menu
***********************************************************************************/

.v-menu {
  position: relative;
}


  /**
  ** Container
  ****************************************/

  .v-menu__container {

    @include mq($from: large) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
      align-items: center;
    }
  }


    /**
    ** Main
    ****************************************/

    .v-menu__main {

      @include mq($until: small) {
        @include rem(padding-top, $gutter-size);
      }

      @include mq($from: small, $until: medium) {
        @include rem(padding-top, $gutter-size*1.5);
      }

      @include mq($from: medium, $until: large) {
        @include rem(padding-top, $gutter-size*2);
      }

      @include mq($until: large) {
        text-align: center;
        border-top: 1px solid $color-grey;
      }

      @include mq($from: large) {
        order: 2;
        width: 50%;
      }
    }


      /**
      ** Title
      ****************************************/

      .v-menu__title {
        @include font-size(36px, 46px);
      }


      /**
      ** Content
      ****************************************/

      .v-menu__content {

        &:not(:first-child) {
          @include rem(margin-top, $gutter-size/2);
        }

        p {

          &:not(:first-child) {
            @include rem(margin-top, $gutter-size/2);
          }
        }
      }


      /**
      ** More
      ****************************************/

      .v-menu__more {

        &:not(:first-child) {
          @include rem(margin-top, $gutter-size);
        }
      }


    /**
    ** Aside
    ****************************************/

    .v-menu__aside {

      @include mq($until: small) {
        @include rem(margin-top, $gutter-size);
      }

      @include mq($from: small, $until: medium) {
        @include rem(margin-top, $gutter-size*1.5);
      }

      @include mq($from: medium, $until: large) {
        @include rem(margin-top, $gutter-size*2);
      }

      @include mq($until: large) {
        @include rem(padding-top, $gutter-size*2);
        border-top: 1px solid $color-grey;
      }

      @include mq($from: large) {
        order: 1;
        width: 50%;
      }
    }


      /**
      ** Items
      ****************************************/

      .v-menu__items {}


        /**
        ** Item
        ****************************************/

        .v-menu__item {

          @include mq($until: large) {
            text-align: center;
          }

          &:not(:first-child) {
            @include rem(margin-top, $gutter-size);
          }
        }


          /**
          ** Link
          ****************************************/

          .v-menu__link {
            position: relative;
            display: inline-block;
            @include rem(padding-right, 5px);
            @include rem(padding-left, 5px);
            font-weight: $font-bold;

            &:before {
              content: '';
              position: absolute;
              @include rem(height, 10px);
              right: 0;
              bottom: 0;
              left: 0;
              background-color: $color-primary;
              z-index: -1;
            }

            @include mq($until: small) {
              @include font-size(26px, 26px);
            }

            @include mq($from: small, $until: medium) {
              @include font-size(30px, 30px);
            }

            @include mq($from: medium) {
              @include font-size(36px, 36px);
            }
          }

          .v-menu__link--small {
            @include font-size(18px, 18px);

            &:before {
              @include rem(bottom, -2px);
              transition: transform 0.2s ease;
              transform-origin: left;
            }

            &:hover {

              &:before {
                transform: scaleX(0);
                transform-origin: right;
              }
            }
          }
