/**********************************************************************************
** COMPONENT
** Name : Title
***********************************************************************************/

.c-title {
  position: relative;
  font-weight: $font-bold;

  @include mq($until: small) {
    @include font-size(38px, 32px);
  }

  @include mq($from: small, $until: medium) {
    @include font-size(50px, 43px);
  }

  @include mq($from: medium, $until: large) {
    @include font-size(62px, 54px);
  }

  @include mq($from: large) {
    @include font-size(74px, 65px);
  }
}

/**
** Small
****************************************/

.c-title--small {
  @include rem(padding-bottom, $gutter-size/2);

  &:after {
    content: '';
    position: absolute;
    @include rem(width, 100px);
    @include rem(height, 10px);
    bottom: 0;
    left: 0;
    background-color: $color-primary;
  }

  @include mq($until: small) {
    @include font-size(26px, 26px);
  }

  @include mq($from: small, $until: medium) {
    @include font-size(32px, 32px);
  }

  @include mq($from: medium, $until: large) {
    @include font-size(38px, 38px);
  }

  @include mq($from: large) {
    @include font-size(44px, 44px);
  }
}
