/**********************************************************************************
** COMPONENT
** Name : Contact
***********************************************************************************/

.c-contact {
  position: relative;
}


  /**
  ** Body
  ****************************************/

  .c-contact__body {
    text-align: center;
  }


  /**
  ** Foot
  ****************************************/

  .c-contact__foot {
    text-align: center;

    &:not(:first-child) {
      border-top: 1px solid $color-foreground;

      @include mq($until: small) {
        @include rem(margin-top, $gutter-size);
        @include rem(padding-top, $gutter-size);
      }

      @include mq($from: small, $until: medium) {
        @include rem(margin-top, $gutter-size*1.5);
        @include rem(padding-top, $gutter-size*1.5);
      }

      @include mq($from: medium, $until: large) {
        @include rem(margin-top, $gutter-size*2);
        @include rem(padding-top, $gutter-size*2);
      }

      @include mq($from: large) {
        @include rem(margin-top, $gutter-size*3);
        @include rem(padding-top, $gutter-size*3);
      }
    }
  }


    /**
    ** Items
    ****************************************/

    .c-contact__items {}


      /**
      ** Item
      ****************************************/

      .c-contact__item {

        &:not(:first-child) {

          @include mq($until: medium) {
            @include rem(margin-top, $gutter-size);
          }

          @include mq($from: medium, $until: large) {
            @include rem(margin-top, $gutter-size*1.5);
          }

          @include mq($from: large) {
            @include rem(margin-top, $gutter-size*2);
          }
        }
      }


        /**
        ** Label
        ****************************************/

        .c-contact__label {

          @include mq($until: medium) {
            @include font-size(16px, 20px);
          }

          @include mq($from: medium) {
            @include font-size(21px, 28px);
          }
        }


        /**
        ** Value
        ****************************************/

        .c-contact__value {
          font-weight: $font-bold;

          @include mq($until: small) {
            @include font-size(14px, 20px);
          }

          @include mq($from: small, $until: medium) {
            @include font-size(22px, 24px);
          }

          @include mq($from: medium) {

            &:not(:first-child) {
              @include rem(margin-top, $gutter-size/4);
            }
          }

          @include mq($from: medium, $until: large) {
            @include font-size(28px, 28px);
          }

          @include mq($from: large) {
            @include font-size(36px, 36px);
          }

          a {
            position: relative;
            display: inline-block;
            @include rem(padding-right, 5px);
            @include rem(padding-left, 5px);

            &:before {
              content: '';
              position: absolute;
              @include rem(height, 10px);
              right: 0;
              bottom: 0;
              left: 0;
              background-color: $color-primary;
              transition: transform 0.4s ease;
              transform-origin: left;
              z-index: -1;
            }

            &:hover {

              &:before {
                transform: scaleX(0);
                transform-origin: right;
              }
            }
          }
        }
