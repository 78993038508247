/**********************************************************************************
** COMPONENT
** Name : Date
***********************************************************************************/

.c-date {
  position: relative;

  .v-events__item & {
    position: inherit;

    &:before {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;
    }
  }
}


/**
** Types
****************************************/

.c-date--centered {}


  /**
  ** Container
  ****************************************/

  .c-date__container {}


    /**
    ** Inner
    ****************************************/

    .c-date__inner {
      display: flex;
      flex-direction: column;
      flex-wrap: nowrap;
      align-items: flex-start;

      .c-date--centered & {
        align-items: center;
      }

      @include mq($from: small, $until: large) {
        align-items: center;
        text-align: center;
      }
      
      .v-dates & {

        @include mq($from: large) {
          align-items: flex-end;
          text-align: right;
        }
      }
    }


      /**
      ** Elements
      ****************************************/

      .c-date__label,
      .c-date__title,
      .c-date__subtitle,
      .c-date__caption {
        text-transform: uppercase;
      }


      /**
      ** Head
      ****************************************/

      .c-date__head {
        display: flex;
        flex-direction: row;
        flex-wrap: nowrap;
        align-items: center;
        justify-content: flex-end;

        @include mq($until: large) {
          @include font-size(14px, 14px);

          .v-overview & {

            @include mq($from: small) {
              @include font-size(16px, 16px);
            }
          }
        }

        @include mq($from: large, $until: xlarge) {
          @include font-size(16px, 16px);

          .v-overview & {
            @include font-size(18px, 18px);
          }
        }

        @include mq($from: xlarge) {
          @include font-size(20px, 20px);

          .v-overview & {
            @include font-size(22px, 22px);
          }
        }
      }


        /**
        ** Label
        ****************************************/

        .c-date__label {
          position: relative;
          @include rem(padding-right, 5px);
          @include rem(padding-left, 5px);

          &:before {
            content: '';
            position: absolute;
            @include rem(height, 10px);
            right: 0;
            @include rem(bottom, -4px);
            left: 0;
            background-color: $color-primary;
            z-index: -1;
          }

          &:not(:first-child) {
            margin-left: rem($gutter-size/2);
          }
        }


        /**
        ** Tag
        ****************************************/

        .c-date__tag {
          display: inline-block;
          padding: rem($gutter-size/8 $gutter-size/4);
          font-weight: $font-bold;
          text-transform: uppercase;
          color: $color-white;
          background: linear-gradient(to top right, $color-primary, $color-secondary);
        }


      /**
      ** Title
      ****************************************/

      .c-date__title {
        font-weight: $font-bold;

        &:not(:first-child) {
          @include rem(margin-top, $gutter-size/4);
        }

        @include mq($until: small) {
          @include font-size(30px, 30px);
          letter-spacing: $letter-spacing/2;

          .v-overview & {
            @include font-size(44px, 44px);
          }
        }

        @include mq($from: small, $until: medium) {
          @include font-size(42px, 42px);
          letter-spacing: $letter-spacing/2;

          .v-overview & {
            @include font-size(65px, 65px);
          }
        }

        @include mq($from: medium, $until: large) {
          @include font-size(54px, 54px);
          letter-spacing: $letter-spacing;

          .v-overview & {
            @include font-size(78px, 78px);
          }
        }

        @include mq($from: large, $until: xlarge) {
          @include font-size(65px, 65px);
          letter-spacing: $letter-spacing;

          .v-overview & {
            @include font-size(78px, 78px);
          }
        }

        @include mq($from: xlarge) {
          @include font-size(75px, 75px);
          letter-spacing: $letter-spacing;

          .v-overview & {
            @include font-size(90px, 90px);
          }
        }
      }


      /**
      ** Subtitle
      ****************************************/

      .c-date__subtitle {
        font-weight: $font-bold;

        @include mq($until: small) {
          @include font-size(18px, 18px);

          .v-overview & {
            @include font-size(21px, 21px);
          }
        }

        @include mq($from: small, $until: medium) {
          @include font-size(21px, 21px);

          .v-overview & {
            @include font-size(27px, 27px);
          }
        }

        @include mq($from: medium, $until: large) {
          @include font-size(24px, 24px);

          .v-overview & {
            @include font-size(30px, 30px);
          }
        }

        @include mq($from: large, $until: xlarge) {
          @include font-size(28px, 28px);

          .v-overview & {
            @include font-size(30px, 30px);
          }
        }

        @include mq($from: xlarge) {
          @include font-size(30px, 28px);

          .v-overview & {
            @include font-size(34px, 34px);
          }
        }
      }


      /**
      ** Caption
      ****************************************/

      .c-date__caption {
        font-weight: $font-bold;
        color: $color-primary;

        &:not(:first-child) {
          @include rem(margin-top, $gutter-size/8);
        }

        @include mq($until: small) {
          @include font-size(14px, 14px);
        }

        @include mq($from: small, $until: medium) {
          @include font-size(16px, 16px);
        }

        @include mq($from: medium, $until: large) {
          @include font-size(18px, 18px);
        }

        @include mq($from: large) {
          @include font-size(20px, 20px);
        }
      }
