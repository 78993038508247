/**********************************************************************************
** LAYOUT
** Name : Gallery
***********************************************************************************/

.l-gallery {

  @include mq($until: large) {
    display: none;
  }

  @include mq($from: large) {
    position: relative;
  }
}


  /**
  ** Container
  ****************************************/

  .l-gallery__container {

    @include mq($from: large, $until: xlarge) {
      @include rem(margin-right, $gutter-size*1.5);
      @include rem(margin-left, $gutter-size*1.5);
    }

    @include mq($from: xlarge) {
      @include rem(margin-right, $gutter-size*3);
      @include rem(margin-left, $gutter-size*3);
    }
  }


    /**
    ** Inner
    ****************************************/

    .l-gallery__inner {
      position: relative;
    }


      /**
      ** More
      ****************************************/

      .l-gallery__more {
        position: absolute;
        width: calc(33.333% - 26px);
        top: calc(50% - 1px);
        right: 0;
        bottom: 40px;
      }


        /**
        ** Link
        ****************************************/

        .l-gallery__link {
          display: flex;
          flex-direction: column;
          flex-wrap: nowrap;
          align-items: center;
          justify-content: center;
          width: 100%;
          height: 100%;
          text-align: center;
          color: $color-foreground;
          background-color: rgba(#000, 0.9);
          transition: background-color 0.2s ease;

          &:hover {
            background-color: rgba(#000, 0.85);
          }
        }


          /**
          ** Illustration
          ****************************************/

          .l-gallery__illustration {
            position: relative;

            @include mq($until: medium) {
              @include rem(width, 36px);
            }
          }


          /**
          ** Icon
          ****************************************/

          .l-gallery__icon {

            &:first-child {
              position: relative;
              z-index: 1;
            }

            &:nth-child(2),
            &:nth-child(3) {
              position: absolute;
              top: 0;
              left: 0;
              -webkit-clip-path: polygon(0 0, 0 0, 0 0, 0 0);
              clip-path: polygon(0 0, 0 0, 0 0, 0 0);
            }

            &:nth-child(2) {
              color: $color-primary;
            }

            &:nth-child(3) {
              color: $color-secondary;
            }

            .l-gallery__link:hover & {

              &:first-child {
                animation-name: glitch-anim-text;
                animation-duration: 3s;
                animation-timing-function: linear;
                animation-iteration-count: infinite;
                animation-delay: 0.01s;
              }

              &:nth-child(2),
              &:nth-child(3) {
                animation-name: glitch-anim-text-back;
                animation-timing-function: linear;
                animation-iteration-count: infinite;
              }

              &:nth-child(2) {
                animation-duration: 1s;
                animation-delay: 0.2s;
              }

              &:nth-child(3) {
                animation-duration: 1.2s;
                animation-delay: 0.5s;
              }
            }
          }


          /**
          ** Label
          ****************************************/

          .l-gallery__label {

            @include mq($until: small) {
              display: none;
            }

            @include mq($from: small) {
              font-weight: $font-bold;

              &:not(:first-child) {

                @include mq($until: medium) {
                  @include rem(margin-top, $gutter-size/4);
                  @include font-size(12px, 16px);
                }

                @include mq($from: medium) {
                  @include rem(margin-top, $gutter-size/2);
                  @include font-size(14px, 20px);
                }
              }
            }
          }


      /**
      ** Widget
      ****************************************/

      .l-gallery__widget {
        pointer-events: none;

        iframe {
          display: block;
        }
      }
