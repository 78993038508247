/**********************************************************************************
** VIEW
** Name : Overview
***********************************************************************************/

.v-overview {
  position: relative;
}


  /**
  ** Container
  ****************************************/

  .v-overview__container {

    @include mq($from: large) {
      display: flex;
      flex-direction: row;
      flex-wrap: nowrap;
    }
  }


    /**
    ** Aside
    ****************************************/

    .v-overview__aside {

      @include mq($until: large) {
        display: none;
      }

      @include mq($from: large) {
        flex: 0 0 auto;
      }
      
      @include mq($from: large, $until: xlarge) {
        @include rem(width, 360px);
      }
      
      @include mq($from: xlarge) {
        @include rem(width, 520px);
      }
    }


    /**
    ** Main
    ****************************************/

    .v-overview__main {

      @include mq($from: small, $until: large) {
        text-align: center;
      }

      @include mq($from: large) {
        flex: 1 1 auto;
      }
      
      @include mq($from: large, $until: xlarge) {
        @include rem(margin-left, $gutter-size*1.5);
      }
      
      @include mq($from: xlarge) {
        @include rem(margin-left, $gutter-size*2);
      }
    }


      /**
      ** Heading
      ****************************************/

      .v-overview__heading {}


      /**
      ** Picture
      ****************************************/

      .v-overview__picture {

        @include mq($until: large) {

          .v-overview__main & {
            display: block;
            width: 100%;
            @include rem(max-width, 380px);
            margin-right: auto;
            margin-left: auto;

            &:not(:first-child) {
              @include rem(margin-top, $gutter-size);
            }
          }
        }

        @include mq($from: large) {

          .v-overview__main & {
            display: none;
          }
        }
      }


      /**
      ** More
      ****************************************/

      .v-overview__more {

        @include mq($until: small) {
          text-align: center;
        }

        &:not(:first-child) {

          @include mq($until: medium) {
            @include rem(margin-top, $gutter-size);
          }

          @include mq($from: medium) {
            @include rem(margin-top, $gutter-size*1.5);
          }
        }
      }


        /**
        ** Tag
        ****************************************/

        .v-overview__tag {
          display: inline-block;
          margin-bottom: rem($gutter-size/8);
          padding: rem($gutter-size/8 $gutter-size/4);
          font-weight: $font-bold;
          text-transform: uppercase;
          color: $color-white;
          background: linear-gradient(to top right, $color-primary, $color-secondary);

          @include mq($until: medium) {
            @include font-size(22px, 26px);
          }

          @include mq($from: medium) {
            @include font-size(28px, 32px);
          }
        }


        /**
        ** Upcoming
        ****************************************/

        .v-overview__upcoming {
          position: relative;
          display: inline-block;
          font-weight: $font-bold;

          @include mq($until: medium) {
            @include font-size(16px, 20px);
          }

          @include mq($from: medium) {
            @include font-size(20px, 24px);
          }
        }


      /**
      ** Infos
      ****************************************/

      .v-overview__infos {

        &:not(:first-child) {

          @include mq($until: medium) {
            @include rem(margin-top, $gutter-size);
          }

          @include mq($from: medium) {
            @include rem(margin-top, $gutter-size*1.5);
          }
        }
      }


        /**
        ** Info
        ****************************************/

        .v-overview__info {

          &:not(:first-child) {

            @include mq($until: medium) {
              @include rem(margin-top, $gutter-size/2);
            }

            @include mq($from: medium) {
              @include rem(margin-top, $gutter-size);
            }
          }
        }


          /**
          ** Label
          ****************************************/

          .v-overview__label {
            position: relative;
            display: inline-block;
            font-weight: $font-bold;

            &:before {
              content: '';
              position: absolute;
              @include rem(height, 10px);
              @include rem(right, -5px);
              @include rem(bottom, 2px);
              @include rem(left, -5px);
              background-color: $color-primary;
              z-index: -1;
            }

            @include mq($until: medium) {
              @include font-size(22px, 26px);
            }

            @include mq($from: medium) {
              @include font-size(28px, 32px);
            }
          }


          /**
          ** Value, Suffix
          ****************************************/

          .v-overview__value {}
          .v-overview__suffix {}


            /**
            ** Link
            ****************************************/

            .v-overview__link {
              position: relative;
              display: inline-block;
              @include rem(padding-right, 5px);
              @include rem(padding-left, 5px);
              @include font-size(18px, 18px);
              font-weight: $font-bold;

              &:before {
                content: '';
                position: absolute;
                @include rem(height, 10px);
                right: 0;
                @include rem(bottom, -2px);
                left: 0;
                background-color: $color-primary;
                transition: transform 0.2s ease;
                transform-origin: left;
                z-index: -1;
              }

              &:hover {

                &:before {
                  transform: scaleX(0);
                  transform-origin: right;
                }
              }
            }
