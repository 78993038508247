/**********************************************************************************
** VIEW
** Name : Dates
***********************************************************************************/

.v-dates {
  position: relative;
}


  /**
  ** Items
  ****************************************/

  .v-dates__items {
    display: flex;
    flex-direction: column;
    flex-wrap: nowrap;

    @include mq($until: small) {
      align-items: flex-start;
    }

    @include mq($from: small, $until: large) {
      align-items: center;
    }

    @include mq($from: large) {
      align-items: flex-end;
    }
  }


    /**
    ** Item
    ****************************************/

    .v-dates__item {

      @include mq($until: medium) {
        width: 100%;
      }

      &:not(:first-child) {

        @include mq($until: small) {
          @include rem(margin-top, $gutter-size*0.75);
          @include rem(padding-top, $gutter-size*0.75);
        }

        @include mq($from: small,$until: medium) {
          @include rem(margin-top, $gutter-size);
          @include rem(padding-top, $gutter-size);
        }

        @include mq($until: medium) {
          border-top: 1px solid #444;
        }

        @include mq($from: medium) {
          @include rem(margin-top, $gutter-size*1.5);
        }
      }
    }
